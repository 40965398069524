"use client";

import { useState } from "react";
import {
  Dialog,
  DialogPanel,
  Disclosure,
  DisclosureButton,
  DisclosurePanel,
  Popover,
  PopoverButton,
  PopoverGroup,
  PopoverPanel,
} from "@headlessui/react";
import {
  ArrowPathIcon,
  Bars3Icon,
  ChartPieIcon,
  CursorArrowRaysIcon,
  FingerPrintIcon,
  SquaresPlusIcon,
  XMarkIcon,
} from "@heroicons/react/24/outline";
import {
  ChevronDownIcon,
  PhoneIcon,
  PlayCircleIcon,
} from "@heroicons/react/20/solid";
import { useDispatch, useSelector } from "react-redux";
import { useNavigate } from "react-router-dom";
import { Button } from "@mui/material";
import { selectUserRoles } from "../../redux/selectors";
import { logout } from "../../redux/authSlice";
import { useMsal } from "@azure/msal-react";

export default function MenuBar() {
  const [mobileMenuOpen, setMobileMenuOpen] = useState(false);

  const userRoles = useSelector(selectUserRoles);
  const dispatch = useDispatch();
  const navigate = useNavigate();
  const { instance } = useMsal();

  let menuItems = [];

  const handleLogout = () => {
    ////console.log("Button clicked!");
    setMobileMenuOpen(false);
    dispatch(logout());

    const accounts = instance.getAllAccounts();
    if (accounts && accounts.length > 0) {
      // Logout from Azure AD
      instance.logoutPopup({
        postLogoutRedirectUri: process.env.REACT_APP_REDIRECT_URI,
      });
    }
    //sessionStorage.clear();
    //localStorage.clear();
    navigate("/");
    // Add your logout logic here
  };

  if (userRoles.includes("ADMIN")) {
    menuItems = [
      { text: "Home", link: "/admin_home" },
      { text: "Profile", link: "/profile" },
      { text: "Manage Employees", link: "/manage_employees" },
      { text: "CP Leave Record", link: "/application-status-admin" },
      { text: "View Timesheet", link: "/view-timesheet-calendar" },
    ];
  } else if (userRoles.includes("CP")) {
    menuItems = [
      { text: "Home", link: "/cp_home" },
      { text: "Profile", link: "/profile" },
      { text: "Absence Request", link: "/absence_request" },
      //{ text: "Additional Work Request", link: "/" },
      { text: "Application Status", link: "/application_status_cp" },
      { text: "View Timesheet", link: "/view_timesheet" },
      // [
      //   { text: "Tags", link: "" },
      //   { text: "React", link: "https://reactjs.org/" },
      //   { text: "JavaScript", link: "https://developer.mozilla.org/en-US/docs/Web/JavaScript" },
      //   { text: "Frontend", link: "https://en.wikipedia.org/wiki/Frontend_development" },
      // ],
    ];
  } else if (userRoles.includes("PL")) {
    menuItems = [
      { text: "Home", link: "/pl_home" },
      { text: "Profile", link: "/profile" },
      { text: "CP Leave Approval", link: "/approval_request" },
      { text: "Absence Request(CP)", link: "/absence_request_pl" },
      { text: "CP Approved Leaves", link: "/approved_request_approved" },
      { text: "View Timesheet", link: "/view-timesheet-calendar" },
    ];
  } else if (userRoles.includes("VENDOR")) {
    menuItems = [
      { text: "Home", link: "/vendor_home" },
      { text: "Profile", link: "/profile" },
      { text: "CP Leave Approval Page", link: "/approval_request_ven" },
      { text: "CP Approved Leaves", link: "/approved_request_approved" },
      { text: "View Timesheet", link: "/view-timesheet-calendar" },
    ];
  } else {
    menuItems = [];
  }

  return (
    <div className="col-span-2 row-start-2 min-w-0">
      <header className="bg-white">
        <nav
          aria-label="Global"
          className="mx-auto flex max-w-7xl items-center lg:justify-start p-6 px-0 pt-4 lg:pt-0 pb-0 "
        >
          <div className="flex lg:flex-1">
            {/* <a href="#" className="-m-1.5 p-1.5">
            <span className="sr-only">HR Management System</span>
            <img src={logo} alt="Logo" className="h-8 w-auto" />
          </a> */}
          </div>
          <div className="flex lg:hidden">
            <button
              type="button"
              onClick={() => setMobileMenuOpen(true)}
              className="-m-2.5 inline-flex items-center justify-center rounded-md p-2.5 text-gray-700"
            >
              <span className="sr-only">Open main menu</span>
              <Bars3Icon aria-hidden="true" className="size-6" />
            </button>
          </div>
          <div>
            <PopoverGroup className="hidden lg:flex lg:gap-x-12 justify-start">
              {menuItems.map((item, index) => (
                <div key={index}>
                  {Array.isArray(item) ? (
                    <>
                      <Popover className="relative">
                        <PopoverButton className="flex items-center gap-x-1 text-sm/6 font-semibold text-gray-900">
                          {item[0].text}
                          <ChevronDownIcon
                            aria-hidden="true"
                            className="size-5 flex-none text-gray-400"
                          />
                        </PopoverButton>

                        <PopoverPanel
                          transition
                          className="absolute -left-8 top-full z-10 mt-3 w-screen max-w-md overflow-hidden rounded-3xl bg-white shadow-lg ring-1 ring-gray-900/5 transition data-[closed]:translate-y-1 data-[closed]:opacity-0 data-[enter]:duration-200 data-[leave]:duration-150 data-[enter]:ease-out data-[leave]:ease-in"
                        >
                          <div className="p-4">
                            {item.slice(1).map((subItem, subIndex) => (
                              <div
                                key={subItem.name}
                                className="group relative flex gap-x-6 rounded-lg p-4 text-sm/6 hover:bg-gray-50"
                              >
                                {/* <div className="mt-1 flex size-11 flex-none items-center justify-center rounded-lg bg-gray-50 group-hover:bg-white">
                        <item.icon aria-hidden="true" className="size-6 text-gray-600 group-hover:text-indigo-600" />
                      </div> */}
                                <div className="flex-auto">
                                  <a
                                    href={subItem.link}
                                    className="block font-semibold text-gray-900"
                                  >
                                    {subItem.text}
                                    <span className="absolute inset-0" />
                                  </a>
                                  <p className="mt-1 text-gray-600">
                                    {subItem.description}
                                  </p>
                                </div>
                              </div>
                            ))}
                          </div>
                        </PopoverPanel>
                      </Popover>
                    </>
                  ) : (
                    <p>
                      {item.link ? (
                        <a
                          href={item.link}
                          className="text-sm/6 font-semibold text-gray-900"
                        >
                          {item.text}
                        </a>
                      ) : (
                        <a
                          href="#"
                          className="text-sm/6 font-semibold text-gray-900"
                        >
                          {item.text}
                        </a>
                      )}
                    </p>
                  )}
                </div>
              ))}
            </PopoverGroup>
          </div>
          <div className="hidden lg:flex lg:flex-1 lg:justify-end">
            <a
              href="#"
              className="text-sm/6 font-semibold text-gray-900"
              onClick={handleLogout}
            >
              Logout <span aria-hidden="true">&rarr;</span>
            </a>
          </div>
        </nav>
        <Dialog
          open={mobileMenuOpen}
          onClose={setMobileMenuOpen}
          className="lg:hidden"
        >
          <div className="fixed inset-0 z-10" />
          <DialogPanel className="fixed pt-24 inset-y-0 right-0 z-10 w-full overflow-y-auto bg-white px-6 py-6 sm:max-w-sm sm:ring-1 sm:ring-gray-900/10 p-6">
            <div className="flex items-center justify-between">
              <a href="#" className="-m-1.5 p-1.5">
                <span className="sr-only">HDB Time Track Plus</span>
                <img
                  alt=""
                  src="https://tailwindui.com/plus/img/logos/mark.svg?color=indigo&shade=600"
                  className="h-8 w-auto"
                />
              </a>
              <button
                type="button"
                onClick={() => setMobileMenuOpen(false)}
                className="-m-2.5 rounded-md p-2.5 text-gray-700"
              >
                <span className="sr-only">Close menu</span>
                <XMarkIcon aria-hidden="true" className="size-6" />
              </button>
            </div>
            <div className="mt-6 flow-root">
              <div className="-my-6 divide-y divide-gray-500/10">
                <div className="space-y-2 py-6">
                  {menuItems.map((item, index) => (
                    <div key={index}>
                      {Array.isArray(item) ? (
                        <>
                          <Disclosure as="div" className="-mx-3">
                            <DisclosureButton className="group flex w-full items-center justify-between rounded-lg py-2 pl-3 pr-3.5 text-base/7 font-semibold text-gray-900 hover:bg-gray-50">
                              {item[0].text}
                              <ChevronDownIcon
                                aria-hidden="true"
                                className="size-5 flex-none group-data-[open]:rotate-180"
                              />
                            </DisclosureButton>
                            <DisclosurePanel className="mt-2 space-y-2">
                              {item.slice(1).map((subItem, subIndex) => (
                                <DisclosureButton
                                  key={subItem.text}
                                  as="a"
                                  href={subItem.link}
                                  className="block rounded-lg py-2 pl-6 pr-3 text-sm/7 font-semibold text-gray-900 hover:bg-gray-50"
                                >
                                  {subItem.text}
                                </DisclosureButton>
                              ))}
                            </DisclosurePanel>
                          </Disclosure>
                        </>
                      ) : (
                        <p>
                          {item.link ? (
                            <a
                              href={item.link}
                              className="-mx-3 block rounded-lg px-3 py-2 text-base/7 font-semibold text-gray-900 hover:bg-gray-50"
                            >
                              {item.text}
                            </a>
                          ) : (
                            <a
                              href="#"
                              className="-mx-3 block rounded-lg px-3 py-2 text-base/7 font-semibold text-gray-900 hover:bg-gray-50"
                            >
                              {item.text}
                            </a>
                          )}
                        </p>
                      )}
                    </div>
                  ))}
                </div>
                <div className="py-6">
                  <a
                    href="#"
                    className="-mx-3 block rounded-lg px-3 py-2.5 text-base/7 font-semibold text-gray-900 hover:bg-gray-50"
                    onClick={handleLogout}
                  >
                    Logout
                  </a>
                </div>
              </div>
            </div>
          </DialogPanel>
        </Dialog>
      </header>
    </div>
  );
}
