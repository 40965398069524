// authSlice.js
import { createSlice } from '@reduxjs/toolkit';

const initialState = {
  isAuthenticated: false,
  user: {
    staffId: '',
    token: '',
    roles: [],
  },
};

const authSlice = createSlice({
  name: 'auth',
  initialState, 
  reducers: {
    login(state, action) {
      console.log('login action received:', action);
       return {
        ...state,
		    isAuthenticated: true,
        user: {
            staffId: action.payload.staffId || '',
			      token: action.payload.token || '',
			      roles: Array.isArray(action.payload.roles) ? action.payload.roles : ['PUBLIC'],
          }
      }
    },
    logout(state) {
    sessionStorage.clear();
    localStorage.clear();
      return {
        ...state,
		    isAuthenticated: false,
        user: {
            staffId: '',
			      token: '',
			      roles: ['PUBLIC'],
          }
      }
    },
    // setUser(state, action) {
    //   if (!action.payload) {
    //     console.warn('setUser action received without payload');
    //     return state;
    //   }

    //   state.user = {
    //     ...state.user,  // Keep existing user data
    //     ...action.payload, // Merge new data
    //     roles: Array.isArray(action.payload.roles) ? 
    //       action.payload.roles : 
    //       (action.payload.roles ? [action.payload.roles] : state.user.roles), // Handle roles carefully
    //   };
    // },
  },
});

export const { login, logout, setUser } = authSlice.actions;
export default authSlice.reducer;
