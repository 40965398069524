import React from "react";

const dynamicImport = (path) => {
  switch (path) {
    case "/":
      return React.lazy(() => import("../Login"));
    case "/vendor_home":
      return React.lazy(() => import("../VendorHome"));
    case "/pl_home":
      return React.lazy(() => import("../PLHome"));
    case "/cp_home":
      return React.lazy(() => import("../CPHome"));
    case "/admin_home":
      return React.lazy(() => import("../AdminHome"));
    case "/add_user":
      return React.lazy(() => import("../AddUser"));
    case "/absence_request":
      return React.lazy(() => import("../AbsenceRequestScreener"));
    case "/absence_request_pl":
      return React.lazy(() => import("../AbsenceRequestScreenerPL"));
    case "/file_upload_recent":
      return React.lazy(() => import("../FileUploadRecent"));
    case "/application_status_cp":
      return React.lazy(() => import("../ApplicationStatusCP"));
    case "/application_status":
      return React.lazy(() => import("../ApplicationStatus"));
    case "/application-status-admin":
      return React.lazy(() => import("../ApplicationStatusAdmin"));
    case "/approval_request":
      return React.lazy(() => import("../ApplicationStatusPL"));
    case "/approval_request_ven":
      return React.lazy(() => import("../ApplicationStatusVendor"));
    case "/approved_request_approved":
      return React.lazy(() => import("../ApprovedApplicationStatusPL"));
    case "/view_timesheet":
      return React.lazy(() => import("../ViewTimesheet"));
    case "/view-timesheet-calendar":
      return React.lazy(() => import("../ViewTimesheetAdmin"));
    case "/profile":
      return React.lazy(() => import("../Profile"));
    case "/manage_employees":
      return React.lazy(() => import("../ManageUser"));
    // Add more paths as needed
    default:
      return React.lazy(() => import("../NoPage"));
  }
};

export default dynamicImport;
